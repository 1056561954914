<template>
  <b-card>
    <b-row class="table-filter">
      <b-col cols="2">
        <b-form-group
          id="input-from-date"
          label="Từ Ngày"
          label-for="from-date"
        >
          <datePicker v-model="filter_from" :config="dpOptions"></datePicker>
        </b-form-group>
      </b-col>

      <!-- filter date to -->
      <b-col cols="2">
        <b-form-group id="input-to-date" label="Đến Ngày" label-for="to-date">
          <datePicker v-model="filter_to" :config="dpOptions"></datePicker>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-button
          block
          variant="success"
          class="search-btn"
          @click="handleSearch"
          >TÌM KIẾM</b-button
        >
      </b-col>
      <b-col cols="2">
        <b-button
          block
          variant="primary"
          class="search-btn"
          @click="handleExport"
          >XUẤT EXCEL</b-button
        >
      </b-col>
    </b-row>
    <b-row>
      <div class="bg-secondary p-3 my-5">
        <div class="pr-3">
          <h3>
            Tổng vé :
            <b class="float-right">{{ stock_amount }}</b>
          </h3>
        </div>
        <div class="pr-3">
          <h3>
            Đã bán :
            <b class="float-right">{{ sale_amount / 10000 }}</b>
          </h3>
        </div>
        <!-- <div class="pr-3">
          <h3>
            Tồn kho :
            <b class="float-right">{{
              this.stock_amount - sale_amount / 10000
            }}</b>
          </h3>
        </div> -->
        <div class="pr-3">
          <h3>
            Doanh thu :
            <b class="float-right">{{ formatPrice(sale_amount) }}</b>
          </h3>
        </div>
      </div>
    </b-row>
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      small
      class="elevation-1 data-table text-center"
    >
      <template v-slot:cell(name)="item">{{ item.value }}</template>
      <template v-slot:cell(phone)="item">{{ item.value }}</template>
      <template v-slot:cell(created_at)="item">{{
        formatDateTime(new Date(item.value))
      }}</template>
      <template v-slot:cell(product_total_price)="item">
        {{ formatPrice(item.value) }}
      </template>
      <template v-slot:cell(payment_fee)="item">
        {{ formatPrice(item.value) || 0 }}
      </template>
      <template v-slot:cell(shipping_fee)="item">
        {{ formatPrice(item.value) || 0 }}
      </template>
      <template v-slot:cell(total)="item"
        >{{ formatPrice(item.value) }}
      </template>
      <template v-slot:cell(order_id)="item">{{ item.value }} </template>
    </b-table>
    <b-row>
      <b-col cols="3">
        Hiển thị {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        trong {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form inline style="margin-right: 20px; margin-bottom: 1rem">
          <label class="mr-sm-2" for="per-page">Số dòng: </label>
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            @change="fetchData"
          ></b-form-select>
        </b-form>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import datePicker from "vue-bootstrap-datetimepicker";
import ApiService from "@/core/services/api.service.js";
import DateUtils from "@/utils/date.js";

// import axios from "axios";
export default {
  name: "Order",
  components: { datePicker },
  data() {
    let { firstDate, lastDate } = DateUtils.getDate(new Date(), 7);
    return {
      isLoading: true,
      isBusy: false,
      headers: [
        { text: "Name", key: "name" },
        { text: "Phone", key: "phone" },
        { text: "Created At", key: "created_at" },
        { text: "Product Total Price", key: "product_total_price" },
        { text: "Payment Fee", key: "payment_fee" },
        { text: "Shipping Fee", key: "shipping_fee" },
        { text: "Total", key: "total" },
        { text: "OrderID", key: "order_id" },
      ],
      // Modal data
      items: [],
      modalTitle: "",
      stock_amount: 0,
      sale_amount: 0,
      remain_amount: 0,
      // Modal validate
      rows: 0,
      perPage: 10,
      currentPage: 1,
      dialog: false,
      dpOptions: {
        format: "MM/DD/YYYY",
        sideBySide: true,
      },
      timeout: null,
      filter_from: firstDate,
      filter_to: lastDate,
      summary: {},
    };
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Traditional Order List" }]);
  },
  computed: {
    campaignId() {
      return this.$store.state.auth.campaignId;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    },
    productList(val) {
      let name = {};
      val.forEach((item) => {
        name[item.value] = item.text;
      });
      this.productName = name;
    },
  },
  methods: {
    openGallery(id) {
      this.$refs[`lightbox_${id}`].showImage(0);
    },
    handleExport() {
      this.exportData("/exports/orders", this.getQueryParams());
    },
    handleSearch() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        this.fetchData();
      }, 400);
    },
    getQueryParams() {
      let to = "";
      if (this.filter_to) {
        to = new Date(this.filter_to);
        to.setDate(to.getDate() + 1);
      }
      let returnObj = {
        limit: this.perPage,
        offset: this.currentPage,
        to,
        from: this.filter_from ? new Date(this.filter_from) : "",
      };
      return returnObj;
    },
    fetchData() {
      ApiService.query(
        "/orders/traditional-order-list",
        this.getQueryParams()
      ).then((response) => {
        this.items = response.data.data.orderList;
        this.summary = response.data.data.summary;
        this.stock_amount = this.summary.summary[0].stock_amount;
        this.sale_amount = this.summary.totalPrice[0].product_total_price;
        this.rows = response.data.data.total;
        this.isBusy = false;
      });
    },
  },
};
</script>
